







import {Component, Prop, Vue} from "vue-property-decorator";
import MeetingList from "./MeetingList.vue";
import axios from "axios";

@Component({
  components: {
    MeetingList,
  },
  props: {
    board: {type: Boolean},
  },
})
export default class PastMeetings extends Vue {
  @Prop()
  public board!: boolean;
  public meetings: any[] = [];
  public title = this.board ? "Past Board meetings" : "Past meetings";
  private url = this.board ? "/board/meetings/past" : "/meetings/past";

  public created() {
    axios.get(this.url)
      .then((result) => {
        this.meetings = result.data;
      });
  }
}
