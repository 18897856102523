









import {Component, Prop, Vue} from "vue-property-decorator";
import axios from "axios";
import {captureException} from "@sentry/browser";


@Component({
  components: {},
  props: ["file", "board"],
})
export default class MeetingAttachment extends Vue {
  @Prop()
  public file: any;

  @Prop(Boolean)
  public board: boolean;

  private tokenUrl = this.board ? "/board/meetings/download-token" : "/meetings/download-token";
  private dlUrl = this.board ? "/board-meeting-downloads/" : "/meeting-downloads/";

  public download() {
    const newWindow = window.open();
    axios.get(this.tokenUrl, {withCredentials: true}).then(() => {
      newWindow.location.href = axios.defaults.baseURL + this.dlUrl + this.file.id;
    }).catch((err) => {
      captureException(err);
      newWindow.close();
    });
  }
}
