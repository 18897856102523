







import {Component, Vue} from "vue-property-decorator";
import UpcomingMeetings from "@/components/meetings/UpcomingMeetings.vue";
import PastMeetings from "@/components/meetings/PastMeetings.vue";

@Component({
  components: {
    UpcomingMeetings,
    PastMeetings,
  },
})
export default class BoardMeetings extends Vue {
}
