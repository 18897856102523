






import {Component, Vue} from "vue-property-decorator";
import Meeting from "./Meeting.vue";

@Component({
  components: {
    Meeting,
  },
  props: ["meetings", "board"],
})
export default class MeetingList extends Vue {
}
